/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-03",
    versionChannel: "nightly",
    buildDate: "2024-06-03T00:05:35.022Z",
    commitHash: "224edfe06fdfa3301519f2850bcecead13f1a96c",
};
